import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraphImage } from "../graph";
import { loginRequest } from "../authConfig";


/**
 * Renders information about the user obtained from MS Graph
 * 
 */
export const ProfileImage = () => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    // need to get access token             
    console.log("Profile image- useEffect")

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      setAccessToken(response.accessToken);
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        setAccessToken(response.accessToken);
      });
    });

    // use accessToken to make call to MS Graph to get profile image
    if (accessToken) {
      callMsGraphImage(accessToken).then(
        (data) => {
          // set profile image and pass as prop to ProfileImage componentand store to sessionStorage
          setImageData(data);
        }
      )

    }

  }, [accessToken, instance, accounts]);



  if (imageData) {
    return (

      <span>
        <span className="sr-only">Open user menu</span>
        <img
          className="h-8 w-8 rounded-full"
          src={imageData.data}
          alt=""
        />
      </span>

    );
  } else {
    return null
  }



};