import {useState, useEffect, useRef, useMemo} from 'react';
import { useSelector } from "react-redux";
import Search from './Search';
import VideoList from './VideoList';
import VideoSearchResultList from './VideoSearchResultList';
import ProjectList from '../project/ProjectList';
import api from '../../services/api';
import '../../assets/css/VideoLibrary.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Tab } from '@headlessui/react';
import { partitionOptions } from './FilterData';


const VideoLibrary = () => {

    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('/search?pageSize=100')
    const [searchTerm, setSearchTerm] = useState('');
    const [showAllVideos, setShowAllVideos] = useState(false);
    const counter = useRef(0);
    const [filterPartition, setFilterPartition] = useState(false);
    const [userVideosOnly, setUserVideosOnly] = useState(true);

    const { user } = useSelector(state => state.auth);
    const authorizedPartitions = partitionOptions();

    const buildPartitionQuery = useMemo(() => {
        if(authorizedPartitions !== undefined && !filterPartition) {
            let partitionQuery = '';
            authorizedPartitions.forEach((partition) => {
                partitionQuery += `&partition=${partition.value}`
            })
            return partitionQuery;
        } else {
            return '';
        }
    }, [authorizedPartitions, filterPartition]);

    useEffect(() => {
        let mounted = true;

        const getVideos = async () => {
            if(mounted) {
                const {data} = await api.get(`${query}${buildPartitionQuery}${userVideosOnly ? '&fullname=' + user.fullname : ''}`);
                setResults(data.results);
                return data.results;
            }
        }

        // code to refresh express token
        //refreshExpressToken();

        // Data based on search term is not fetched until 1 second after the user stops typing in the search field
        if(query && searchTerm === '') {
            getVideos().then((dataresults) => {
                if(dataresults.length === 0) {
                    setLoading(false);
                }
            });
        } else {
            const timeoutId = setTimeout(() => {
                if(query) {
                    counter.current = 0;
                    getVideos().then((dataresults) => {
                        if(dataresults.length === 0) {
                            setLoading(false);
                        }
                    });
                }
            }, 1000);
            return () => {
                clearTimeout(timeoutId);
                mounted = false;
            }
        }
        
        // Cleanup function
        return () => mounted = false;
    }, [buildPartitionQuery, query, searchTerm, showAllVideos, userVideosOnly, user.fullname]);


    const handleSearch = (value, resetSearch = false) => {
        if(`/search?${value}` !== query) {   
            setLoading(true);
            setResults([]);
            counter.current = 0;
            if(!resetSearch) {
                const searchTermToHighlight = value.split('query=').pop().split('&')[0];
                setSearchTerm(searchTermToHighlight);

                if(value.indexOf('partition') > -1) {
                    setFilterPartition(true);
                } else {
                    setFilterPartition(false);
                }
                setQuery(`/search?${value}`);  
            } else {
                counter.current = 0;
                setQuery('/search?')
                setSearchTerm('');
            }
        }
    }


    const handleShowMore = () => {
        if(!showAllVideos) {
            counter.current = 10;
            setLoading(true);
            setShowAllVideos(!showAllVideos);
        } else {
            setShowAllVideos(!showAllVideos);
        }
        
    }

    const handleUserVideosOnly = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        setUserVideosOnly(!userVideosOnly);
    };

    // Keep track of images loaded to drop loading screen
    const imageLoaded = () => {
        counter.current += 1;
        if (results.length >= 10 && !showAllVideos && counter.current === 10) {
          setLoading(false);
        }
          else if(showAllVideos && counter.current === results.length) {
            setLoading(false);
        }
         else if(counter.current === results.length) { // if less than 10 total results
            setLoading(false);
        }
      }

      const showMoreVideosDiv = () => {
        if(!loading && searchTerm === '' && results) {
            if(!showAllVideos) {
                return(
                    <div className="flex items-center justify-start text-lg p-2">
                        <p className="">{`Currently showing ${userVideosOnly ? 'your' : 'the'} latest 10 uploads`}</p>
                        <button className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 ml-3 rounded-full"
                        onClick={() => handleShowMore()}>Show more uploads</button>
                    </div>
                )
            } else {
                return(
                    <div className="flex items-center justify-start text-lg p-2">
                        <p className="">{`Currently showing ${userVideosOnly ? 'your' : ''} ${results.length} uploads`}</p>
                        <button className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 ml-3 rounded-full"
                        onClick={() => handleShowMore()}>Show latest 10 uploads</button>
                    </div>
                )
            } 
        }
    }

      const returnListView = () => {
          const latestUploaded = results.slice(0, 10);
          return (searchTerm !== '' ? <VideoSearchResultList data={results} searchTerm={searchTerm} imageLoaded={imageLoaded}/> 
            : <VideoList data={showAllVideos ? results : latestUploaded} imageLoaded={imageLoaded}/>); 
      }

      const returnVideoView = () => {
          return(
            <>
                <div className="flex pl-2 mt-2">
                    <input type="checkbox" className="appearance-none checked:bg-voa-blue checked:border-transparent focus:ring-0 rounded" 
                    name="myvideos" defaultChecked={userVideosOnly} onChange={handleUserVideosOnly}/>
                    <label className="pl-2" htmlFor="myvideos">Show only my uploaded videos</label>
                </div> 
                {results.length > 10 ? showMoreVideosDiv() : ''}
                <div className="flex flex-col flex-grow justify-center my-1.5 pl-1.5 pr-3" style={{display: loading ? "flex" : "none"}}>
                    <div className="flex flex-col h-full rounded-lg overflow-hidden bg-gray-100 opacity-75 items-center justify-center">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                        <h2 className="text-center text-blue-700 text-xl font-semibold">Loading...</h2>
                        <p className="w-1/3 text-center text-blue-700">This may take a few seconds...</p>
                    </div>
                </div>
                <div className="" style={{display: loading ? "none" : "block"}}>{returnListView()}</div>
             </ >
            
          );
      }

      const returnProjectsView = () => {
          return(       
            <ProjectList searchTerm={searchTerm} imageLoaded={imageLoaded} />
          )
      }

    return(
            <>
            <AuthenticatedTemplate>
                <div className="flex flex-col h-full px-2">
                    <h1 className="text-left pl-2 mt-10 text-3xl">Extract insights and enhance your content</h1>
                    <h2 className="text-left pl-2 mt-2 text-xl">Search for any text, person, insight, or object in your videos</h2>
                    <div className="p-2 mt-4 w-full xl:w-4/5 2xl:w-3/5"><Search handleSearch={handleSearch} /></div> 
                    <Tab.Group>
                        <Tab.List className="text-left py-2">
                            <Tab
                                className={({ selected }) =>
                                selected ? 'border-b-2 border-voa-blue text-black ml-2' : 'bg-white text-black ml-2'
                            }>Library</Tab>
                            <Tab
                                className={({ selected }) =>
                                selected ? 'border-b-2 border-voa-blue text-black ml-2' : 'bg-white text-black ml-2'
                            }>Projects</Tab>
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>{returnVideoView}</Tab.Panel>
                            <Tab.Panel>{returnProjectsView}</Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div> 
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>
                    Not Logged In
                </div>
            </UnauthenticatedTemplate>   
        </>
    );
}

export default VideoLibrary;
