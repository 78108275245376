import React, { useState, useEffect } from 'react';
// import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { editUser, initEdit, getUser } from '../../actions/users';
import { setAlert } from '../../actions/alert';
import { Loading, Alert } from './utils';
import MultiSelect from "react-multi-select-component";
//import './styles/index.css';
// import axios from 'axios';



const EditUser = ({
  setAlert,
  editUser,
  alertContent,
  history,
  editSuccess,
  match,
  isLoading,
  isGetting,
  initEdit,
  getUser,
  user,
  error,
  getError
}) => {
  const id = match.params.userId;
  //const stdRoles = ['Reader', 'Admin', 'Contributor', 'Editor'];

  const [userData, setUserData] = useState({
    fullname: '',
    email: '',
    roles: '',
    partitions: new Array(0),  // array of strings
    partitionData: new Array(0),  // converstion of the partitions to objects
    partitionOptions: new Array(0) // list of ALL partitions from db
  });

  const [userAccess, setUserAccess] = useState(false)

  // const getUserById = (id, setUserData) => {
  //   axios
  //     .get(`http://localhost:5000/api/users/${id}`)
  //     .then(res => {
  //       setUserData({
  //         ...userData,
  //         firstname: res.data.firstname,
  //         lastname: res.data.lastname,
  //         sex: res.data.sex,
  //         age: res.data.age
  //       });
  //     })
  //     .catch(err => setAlert(err));
  // };

  // const cbGetUserById = useCallback((id, getUserById, setUserData) => {
  //   getUserById(id, setUserData);
  // }, id);


  

  useEffect(() => {
    
    getUser(id, setUserData, setUserAccess);
   
    // return () => {
    //   setUserData({ ...user });
    // };
    // getUserById(id, setUserData);
  }, [id, getUser]);
  

  //const x=   getPartitions(setPartitionOptions);
  //console.log(x)

  const { fullname, email, roles, partitions, partitionData, partitionOptions } = userData;


  useEffect(() => {
    
    console.log("userData:",userData)
   
    // return () => {
    //   setUserData({ ...user });
    // };
    // getUserById(id, setUserData);
  }, [userData]);


  // need to get list of partitions from database
  const options = partitionOptions;

  const handleChangePartition = (e) => {
    setUserData({ ...userData, partitionData:e });
  };

  const handleChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleEdit = e => {
    e.preventDefault();

      editUser(
        { id, fullname, email, roles, partitions, partitionData },
        history,
        initEdit
      ); // pass init and push into action-creator and let it exec
    
  };

  const handleBack = () => {
    history.push('/listusers');
  };



  const disableEdit = (
    fullname,
    email,
    roles,
    partitions
  ) => {
    return (
      !(
        fullname &&
        email &&
        roles &&
        partitions
      ) ||
      (user.fullname === fullname &&
        user.email === email &&
        user.roles === roles  &&
        user.partitions === partitions)
    );
  };

  // in render it can do, but not recommand
  // if (editSuccess) {
  //   initEdit();
  //   history.push('/');
  // }

  if (!userAccess) {
    return 'Not Authorized'
  } else {

  return (
    <>
    <div>
      {//   editSuccess ? (
      //   <Redirect to='/' />
      // ) :
      isLoading || isGetting ? (
        <Loading />
      ) : (
        <div className="relative flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100 bg-no-repeat bg-cover">
        <div className="absolute bg-white opacity-60 inset-0 z-0"></div>

        <div className=" w-full p-10 bg-white rounded-xl z-10">
          <div className="text-center">
              <h2 className="mt-5 text-3xl font-bold text-gray-900">
                Edit User
              </h2>
              { /*<p className="mt-2 text-sm text-gray-400">Lorem ipsum is placeholder text.</p> */}
          </div>


      
          <div className="mt-8 flex w-full p-2 space-x-3 justify-center">  
            <form onSubmit={e => handleEdit(e)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <small className='form-text text-muted'>
                Blank with * is required
              </small>
              <div className='form-group'>
                <label htmlFor='fullname'>* Full Name:{' '}</label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  name='fullname'
                  value={fullname}
                  onChange={e => handleChange(e)}
                  placeholder='fullname'
                />
                {!fullname && <Alert warning='empty' item='fullname' />}
                {fullname && !/^[a-zA-Z .]+$/.test(fullname) && (
                  <Alert warning='invalid' item='fullname' />
                )}
              </div>
              <div className='form-group'>
                <label htmlFor='email'>* Email:{' '}</label>
                
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  name='email'
                  value={email}
                  onChange={e => handleChange(e)}
                  placeholder='email'
                />
                {!email && <Alert warning='empty' item='email' />}
                {email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) && (
                  <Alert warning='invalid' item='email' />
                )}
              </div>
              <div className='form-group'>
                <label htmlFor='role'>* Role:{' '}</label>
                <select
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  name='roles'
                  value={roles}
                  onChange={e => handleChange(e)}
                  placeholder='roles'
                >
                  <option></option>
                  <option>Admin</option>
                  <option>Contributor</option>
                  <option>Editor</option>
                  <option>Reader</option>
                  </select>

                {!roles && <Alert warning='empty' item='roles' />}
                
              </div>
              {error && <Alert warning='server' item='edit' />}
              {getError && <Alert warning='server' item='get' />}

              <div className='form-group'>
              <label >* Partition:{' '}</label>
              <MultiSelect
                options={options}
                value={partitionData}
                onChange={e => handleChangePartition(e)}
                labelledBy="Select"
                isCreatable="true"
              />

                {!partitionData && <Alert warning='empty' item='partitionData' />}
                
              </div>
              {error && <Alert warning='server' item='edit' />}
              {getError && <Alert warning='server' item='get' />}




              <div className='flex space-x-4'>
                <div className='float-left'>
                  <button
                    className='btn btn-success'
                    // value='Submit'
                    type='submit'
                    disabled={
                      disableEdit(
                        fullname,
                        email,
                        roles,
                        user
                      )
                      // !(
                      //   firstname &&
                      //   lastname &&
                      //   sex &&
                      //   age &&
                      //   password &&
                      //   repeat &&
                      //   password === repeat
                      // ) ||
                      // (user.firstname === firstname &&
                      //   user.lastname === lastname &&
                      //   user.sex === sex &&
                      //   user.age === age)
                    }
                  >
                     Save User
                  </button>
                </div>
                <div className='btn-middle' />

                <div className='float-right'>
                  <button className='btn btn-secondary' onClick={handleBack}>
                     Back
                  </button>
                </div>
              </div>
            </form>
            <div className='alert-text'>{alertContent}</div>
          </div>
          </div>
        </div>
      )}
    </div>
    </>
  );}
};

const mapStateToProps = state => {
  return {
    alertContent: state.alert.alertContent,
    editSuccess: state.editUser.editSuccess,
    isLoading: state.editUser.isLoading,
    isGetting: state.getUser.isLoading,
    user: state.getUser.user,
    // use this user in file check whether the file be changed in the very bottom
    error: state.editUser.error,
    getError: state.getUser.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alert => dispatch(setAlert(alert)),
    editUser: (data, history) => dispatch(editUser(data, history)),
    initEdit: () => dispatch(initEdit()),
    getUser: (id, setUserData, setUserAccess) => dispatch(getUser(id, setUserData, setUserAccess))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
