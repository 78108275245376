import api from '../services/api';
import EventBus from '../utils/EventBus'
//import { useStore } from "react-redux";
//import { getWithExpiry } from '../utils/sessionWithExpiry';
//import { refreshExpressToken } from '../utils/getExpressToken';


const setUserListStart = () => {
  return {
    type: 'SET_USER_LIST_START',
    payload: { error: null, deleteError: null } // init
  };
};

const setUserListSuccess = data => {
  // data: Array of user obj
  //   console.log(data[0]);
  return {
    type: 'SET_USER_LIST_SUCCESS',
    payload: { users: data }
  };
};

const setUserListError = err => {
  return {
    type: 'SET_USER_LIST_ERROR',
    payload: { error: err }
  };
};

export const setUserList = (setUserAccess) => dispatch => {
  dispatch(setUserListStart());

  //console.log('Bearer:',getWithExpiry("token"))
  api.get("/users/list")
  //axios({ method: "get", url: "/users/list", headers: options})
    .then(res => {
      dispatch(setUserListSuccess(res.data));
      //const store = useStore()
      //console.log("Store:",store.getState())
      setUserAccess(true);
    })
    .catch(err => {
      dispatch(setUserListError(err));
      setUserAccess(false);

      if (err.response && err.response.status === "403.1") {
        EventBus.dispatch("logout");
      }


    })
};

// --------------

const createUserStart = () => {
  return {
    type: 'CREATE_USER_START',
    payload: {}
  };
}; 

const createUserSuccess = userData => {
  // data: user obj: {fn, ln, sex, age, pw}
  return {
    type: 'CREATE_USER_SUCCESS',
    payload: userData
  };
};

const createUserError = err => {
  console.log("this is error:",err)
  return {
    type: 'CREATE_USER_ERROR',
    payload: { error: err }
  };
};

export const createUser = userData => dispatch => {
  dispatch(createUserStart());
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  api
    .post('/users/register', userData, config)
    .then(res => dispatch(createUserSuccess(res.data)))
    .catch(err => dispatch(createUserError(err)));
};

export const initUser = () => dispatch => {
  dispatch({
    type: 'INIT_USER',
    payload: {
      fullname: '',
      email: '',
      id: '',
      roles: '',
      partitions: Array(1),
      createSuccess: false,
      error: null
    }
  });
};

// ---------

const editUserStart = () => {
  return {
    type: 'EDIT_USER_START',
    payload: {}
  };
};

const editUserSuccess = userData => {
  // data: user obj: {fn, ln, sex, age, pw}
  return {
    type: 'EDIT_USER_SUCCESS',
    payload: userData
  };
};

const editUserError = err => {
  return {
    type: 'EDIT_USER_ERROR',
    payload: { error: err }
  };
};

export const editUser = (userData, history, initEdit) => dispatch => {
  dispatch(editUserStart());

  //axios({ method: "put", url: `/users/${userData.id}`, data: userData, headers: options})
  //axios.put(`/users/${userData.id}`, userData, { options })
  api.put(`/users/${userData.id}`, userData)
    .then(res => {
      dispatch(editUserSuccess(res.data));
      // other method:
      history.push('/listusers');
      initEdit();
    })
    .catch(err => {
      dispatch(editUserError(err));
      if (err.response && err.response.status === "403.1") {
        EventBus.dispatch("logout");
      }
    });
};

// -------

export const initEdit = () => dispatch => {
  // console.log('init dispatch');
  dispatch({
    type: 'INIT_EDIT',
    payload: {
      fullname: '',
      email: '',
      roles: '',
      editSuccess: false,
      error: null
    }
  });
};

// --------

const deleteUserStart = () => {
  return {
    type: 'DELETE_USER_START',
    payload: {}
  };
};

const deleteUserSuccess = () => {
  // console.log(userData);
  return {
    type: 'DELETE_USER_SUCCESS'
    // payload: userData
  };
};

const deleteUserError = err => {
  return {
    type: 'DELETE_USER_ERROR',
    payload: { deleteError: err }
  };
};

export const deleteUser = id => dispatch => {
  dispatch(deleteUserStart());
  api
    .delete(`http://localhost:5000/api/users/${id}`)
    .then(() => {
      dispatch(deleteUserSuccess()); //might use if we need deleted id
      dispatch(setUserList());
    })
    .catch(err => dispatch(deleteUserError(err)));
};

// --------

const getUserStart = () => {
  return {
    type: 'GET_USER_START',
    payload: {}
  };
};

const getUserSuccess = userData => {
  // console.log(userData);
  return {
    type: 'GET_USER_SUCCESS',
    payload: { user: userData }
  };
};

const getUserError = err => {
  return {
    type: 'GET_USER_ERROR',
    payload: { error: err }
  };
};

export const convertPartitions = (arrPartitions) => {
  let newArrPartitions = arrPartitions.map(function(x) {
    let obj = {}
    obj.label = x
    obj.value = x
   // console.log(x)
   // console.log(obj)
    return obj
  })
  return newArrPartitions
}

export const getPartitionData =  async () => {

  const res = await api.get("/partitions")
  .then(res => {
      console.log("res partitions:",res);
      return res.data;
   })
   .catch(err => {
    //dispatch(getUserError(err)); 
    //setUserAccess(false);
    console.log("err-xxx:",err)
    console.log(typeof(err.message))
    if (err.message.includes("Request failed with status code 403")) {
      EventBus.dispatch("logout");
    }
  });


  let newP = res.map(x => x.name);
  let allP = newP.map(obj => {
    let rObj = {}
    rObj.label= obj;
    rObj.value = obj;
    return rObj
  });

  return allP

}

export const getUser =  (id, setUserData, setUserAccess) => dispatch => {
  dispatch(getUserStart());

  (async function () {

    const allP = await getPartitionData()

    //axios({ method: "get", url: `/users/${id}`, headers: options})
    api.get(`/users/${id}`)
      .then(res => {
        //console.log("res:",res);
        const { fullname, email, partitions } = res.data;
        const roles = res.data.roles[0]
        const partitionData = convertPartitions(res.data.partitions)
        const userData = { fullname, email, roles, partitions, partitionData   };
        //const partitionOptions = convertPartitions(partitionArray.map(x => x.name))
        //let partitionOptions=partitionArray.map(x => x.data.name)
        //console.log(partitionOptions)
        //console.log(partitionArray)

        //console.log("newP",newP)
        userData.partitionOptions = allP;
        //console.log(userData)
        dispatch(getUserSuccess(userData));
        setUserData(userData);
        setUserAccess(true);
      })
      .catch(err => {
        //console.log(err)
        dispatch(getUserError(err)); 
        setUserAccess(false);
        if (err.response && err.response.status === "403.1") {
          EventBus.dispatch("logout");
        }
      });
  })();   
};

