import TokenService from "../services/token.service";

import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REFRESH_TOKEN
  } from "../actions/types";
  
  const user = TokenService.getUser();
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
    const auth =  (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
        case REFRESH_TOKEN:
          TokenService.updateLocalAccessToken(payload);
          return {
            ...state,
            user: { ...user, accessToken: payload },
          };        
      default:
        return state;
    }
  }

  export default auth;