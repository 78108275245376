//import { getWithExpiry } from '../../utils/sessionWithExpiry';
import tokenService from "../../services/token.service";

export const queryOptions = [{
    value: 'Transcript',
    label: 'Transcript'
}, {
    value: 'Topics',
    label: 'Topics'
}, {
    value: 'Ocr',
    label: 'On Screen Text'
}, {
   value: 'Annotations',
   label: 'Annotations' 
}, {
    value: 'Brands',
    label: 'Brands'
}, {
    value: 'NamedLocations',
    label: 'Named Locations'
}, {
    value: 'NamedPeople',
    label: 'Named People'
}];

export const languageOptions = [{
    value: 'ar-SA',
    label: 'Arabic (Saudi Arabia)'
}, {
    value: 'ar-SY',
    label: 'Arabic Syrian Arab Republic'
}, {
    value: 'ar-PS',
    label: 'Arabic (Palestinian Authority)'
}, {
    value: 'ar-QA',
    label: 'Arabic (Qatar)'
}, {
    value: 'ar-EG',
    label: 'Arabic Egypt'
}, {
    value: 'ar-BH',
    label: 'Arabic Modern Standard (Bahrain)'
}, {
    value: 'ar-OM',
    label: 'Arabic (Oman)'
}, {
    value: 'ar-LB',
    label: 'Arabic (Lebanon)'
}, {
    value: 'ar-AE',
    label: 'Arabic (United Arab Emirates)'
}, {
    value: 'ar-KW',
    label: 'Arabic (Kuwait)'
}, {
    value: 'ar-JO',
    label: 'Arabic (Jordan)'
}, {
    value: 'ar-IQ',
    label: 'Arabic (Iraq)'
}, {
    value: 'ar-IL',
    label: 'Arabic (Israel)'
}, {
    value: 'zh-HK',
    label: 'Chinese (Cantonese Traditional)'
}, {
    value: 'zh-Hans',
    label: 'Chinese (Simplified)'
}, {
    value: 'cs-CZ',
    label: 'Czech'
}, {
    value: 'da-DK',
    label: 'Danish'
}, {
    value: 'nl-NL',
    label: 'Dutch'
}, {
    value: 'en-US',
    label: 'English United States',
}, {
    value: 'en-GB',
    label: 'English United Kingdom'
}, {
    value: 'en-AU',
    label: 'English Australia'
}, {
    value: 'fi-FI',
    label: 'Finnish'
}, {
    value: 'fr-CA',
    label: 'French (Canada)',
}, {
    value: 'fr-FR',
    label: 'French',
}, {
    value: 'de-DE',
    label: 'German',
}, {
    value: 'hi-IN',
    label: 'Hindi',
}, {
    value: 'it-IT',
    label: 'Italian',
}, {
    value: 'ja-JP',
    label: 'Japanese',
}, {
    value: 'ko-KR',
    label: 'Korean'
}, {
    value: 'nb-NO',
    label: 'Norwegian',
}, {
    value: 'pl-PL',
    label: 'Polish',
}, {
    value: 'pt-BR',
    label: 'Portuguese',
}, {
    value: 'ru-RU',
    label: 'Russian',
}, {
    value: 'es-ES',
    label: 'Spanish',
}, {
    value: 'es-MX',
    label: 'Spanish (Mexico)',
}, {
    value: 'sv-SE',
    label: 'Swedish',
}, {
    value: 'tr-TR',
    label: 'Turkish',
}, {
    value: 'th-TH',
    label: 'Thai',
}];

export const partitionOptions = () => {
    let partitionOptions = [];
    const data = tokenService.getUser();
    if(data) {
        data.partitions.forEach((partition) => {
            partitionOptions.push({ 
                "label" : partition,
                "value"  : partition
            });
        })
    } else {
        partitionOptions.push({ 
            "label" : "No Partitions Assigned",
            "value"  : "nodata"
        });
    }
    return partitionOptions;
}

