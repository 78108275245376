import api from "./api";
import TokenService from "./token.service";

// MSAL imports
import * as msal from "@azure/msal-browser";
import { msalConfig } from '../authConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const API_URL = "/users/";

const login = async () => {

      // need to get access token from MSAL, then can get an express token           
      var request = {
        scopes: ["Mail.Read"]
      };
      
      const msalInstance = new msal.PublicClientApplication(msalConfig);

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      // tokenResponse will have a MSAL idtoken
      let tokenResponse
      try {
       tokenResponse = await msalInstance.acquireTokenSilent(request);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          tokenResponse = await msalInstance.acquireTokenRedirect(request);
        }
      }

      const bearer = `Bearer ${tokenResponse.idToken}`;
      const options = {
        headers: {'Authorization': bearer}
      };
      

      console.log(options)

  return api
    .post(API_URL + "authenticate", {}, options)
    .then((response) => {
      if (response.data) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return TokenService.getUser();
};

const AuthService =  {
  login,
  logout,
  getCurrentUser
};

export default AuthService;