import {useEffect, useState} from 'react';
import api from '../../services/api';
import ReactHtmlParser from 'react-html-parser';


const ProjectShow = ({match}) => {

    const [content, setContent] = useState(null);
    const id = match.params.id;

    useEffect(() => {
        const getProject = async () => {
            let res = {};
            try {
                if(!match.params.id) {
                    res = await api.get(`/project`);
                } else {
                    res = await api.get(`/project?projectid=${id}`);
                }
                setContent(res.data);
            } catch (err) {
                setContent("Not Authorized")
            }

        }
        getProject();
    })
    
    return(
        <div className="p-4 flex w-full h-full">{ReactHtmlParser(content)}</div>
    )
}

export default ProjectShow;