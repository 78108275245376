import React from 'react';
import VideoSearchResultCard from './VideoSearchResultCard';



const VideoSearchResultList = ({data, searchTerm, imageLoaded}) => {

      const returnCards = data.map((result) => {
        return(
            <VideoSearchResultCard 
                key={result.id}
                id={result.id}
                name={result.name}
                uploadedBy={result.metadata}
                createdDate={result.created}
                thumbnailId={result.thumbnailId}
                processingProgress={result.processingProgress}
                searchMatches={result.searchMatches}
                searchTerm={searchTerm}
                imageLoaded={imageLoaded}
            />
        )
    });

    if(data.length === 0) {
        return <p>No Results Found...</p>
    } else {
        return(
            <div className="m-2">
                {returnCards}
            </div>
    );
    }
}

export default VideoSearchResultList;