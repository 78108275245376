import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import '../../assets/css/VideoCard.css';
//import { refreshExpressToken } from '../../utils/getExpressToken';


const VideoCard = ({id, name, uploadedBy, thumbnailId, processingProgress, imageLoaded, videoActionToTake}) => {

    const [imageUrl, setImageUrl] = useState();
    const [inProgress, setInProgress] = useState((processingProgress !== '100%') ? true : false);
    const [updatedProcessingProgress, setUpdatedProcessingProgress] = useState(processingProgress);
    const [updatedThumbnailId, setUpdatedThumbnailId] = useState(thumbnailId);



    useEffect(() => {
        let mounted = true;
        const getThumbnail = async () => {
            if(mounted) {
                const {data}= await api.get(`/thumbnail?videoid=${id}&thumbnailid=${updatedThumbnailId}&state=${updatedProcessingProgress}`, {
                    responseType: 'blob',
                    timeout: 30000
                });
                setImageUrl(URL.createObjectURL(data));
            }
        }

        const getVideo = async () => {
            if(mounted) {
                const {data} = await api.get(`search?id=${id}`);
                return data;
            }
        }
        //refreshExpressToken();
        getThumbnail();

        if(inProgress) {
            const intervalId = setInterval(() => {
                getVideo().then((data) => {
                    if(data && data.results[0].processingProgress === '100%') {
                        setUpdatedThumbnailId(data.results[0].thumbnailId);
                        setInProgress(false);
                        clearInterval(intervalId);
                    } else if(data) {
                        setUpdatedProcessingProgress(data.results[0].processingProgress);
                    } 
                    
                });
            }, 30000);
        }

        // cleanup function
        return () => mounted = false;
    }, [id, updatedProcessingProgress, updatedThumbnailId, inProgress, processingProgress, thumbnailId]);

    return(
        <div className="max-w-sm rounded overflow-hidden shadow-lg m-2  bg-gray-50 transform hover:scale-105 hover:shadow-xl cardContainer">
            <Link to={inProgress ? '#' : `/video/${id}`} className="stretched-link text-decoration-none">
                <div className={inProgress ? 'inProgress' : ''}
                    data-content={inProgress ? `Video processing in progress: ${updatedProcessingProgress}` : ''}>
                    <img className="w-full" src={imageUrl} onLoad={imageLoaded} alt={`Thumbnail of ${name}`} />
                </div>
            <div className="px-6 py-4">
            
                <div className="font-medium text-base mb-2 truncate">{name}</div>
                <p className="text-gray-700 text-base">
                Uploaded by {uploadedBy !== null ? uploadedBy : 'User'}
                </p>
            </div>
            </Link>
            <div className="flex justify-between w-full pb-2 cardActions">
                <button className="bg-voa-light-blue rounded text-white ml-4" onClick={() => videoActionToTake('reindex', id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                </button>
                <button className="bg-voa-light-blue rounded text-white mr-4" onClick={() => videoActionToTake('delete', id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </button>
            </div>
    </div>
    );
}

export default VideoCard;
