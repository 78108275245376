import { Router, Route, Switch } from 'react-router-dom';

import { PageLayout } from "./PageLayout";
import React, { useCallback, useEffect } from 'react';


import Home from './Home';
import {HomeLink, LibraryLink, UploadLink, UserManagementLink} from './SidePanelLinks'
import VideoLibrary from './video/VideoLibrary';
import VideoShow from './video/VideoShow';
import ProjectShow from './project/ProjectShow';
import UploadFile from './file-upload/UploadFile';
import "../assets/css/App.css";
import { SignOut } from "../SignOut";
import { ProfileData } from "./ProfileData";
import ListUsers from "./users/listUsers"
import EditUser from "./users/edituser"
import CreateUser from "./users/createuser"


// MSAL imports
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
//import { CustomNavigationClient } from "../utils/NavigationClient";

import { history } from "../utils/history"

import EventBus from "../utils/EventBus";

import { logout } from "../actions/auth";

import { useDispatch } from "react-redux";


/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = (props) => {


    return (
        <div className="App text-center">
             
            <div className="flex flex-col sm:flex-row">
 
                <AuthenticatedTemplate>
                    <div id="sidebar" className="hidden xs:block w-full sm:w-16 bg-white px-4 items-center sticky top-0 shadow sm:hover:w-48 hover:delay-500 flex-none">
                        <div className="flex sm:flex-col w-full justify-center">
                            <HomeLink />
                            <LibraryLink />
                            <UploadLink />
                            <UserManagementLink /> 
                        </div>
                    </div>
                    <div className="flex-auto overflow-y-auto overflow-x-hidden">
                        <Switch>

                            {/* routes for Sidebar links */}
                            <Route path="/" exact render={() => (<Home {...props} />)} />   
                            <Route path="/library" exact component={VideoLibrary} />
                            <Route path="/upload" exact component={UploadFile} />  

                            {/* other routes */}
                            <Route path="/signout" exact component={SignOut} />
                            <Route path="/profile" exact component={ProfileData} />

                            
                       
                            <Route path="/video/:id" component={VideoShow} />
                            <Route path="/project/:id" component={ProjectShow} />
                            <Route path="/newproject" exact component={ProjectShow} />

                            <Route exact path='/listusers' component={ListUsers} />
                            <Route exact path='/edituser/:userId' component={EditUser} />
                            <Route exact path='/createuser' component={CreateUser} />
                           

                            { /* handle all other routes to HOME */}
                            <Route component={Home} />
                        </Switch>
                    </div>
                </AuthenticatedTemplate>
                
                <UnauthenticatedTemplate>
                    <div className="flex-auto">
                        <Switch>
                            <Route path="/" exact render={(props) => (<Home />)} />

                            { /* handle all other routes to HOME */}
                            <Route component={Home} />
                        </Switch>
                    </div>    
                </UnauthenticatedTemplate>    
                               
            </div>
        </div>
    );
};

export default function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  /*const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  */

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
    sessionStorage.clear()
    
    window.location.href = "/" 
  }, [dispatch]);

  useEffect(() => {

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

    return (
        <Router history={history}>
            <MsalProvider instance={pca}>
                <PageLayout>
                    <MainContent />
                </PageLayout>
            </MsalProvider>
        </Router>
    );
}
