import React, {useRef} from 'react'

export const FileUploader = ({onFileSelectError,onFileSelectSuccess}) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        // handle validations
        const file = e.target.files[0];
       // console.log(file.size)
        if (file.size > (1024*1024*1024*2))
          onFileSelectError({ error: "File size cannot exceed more than 2GB" });
        else onFileSelectSuccess(file);
        
      };

    return (
        <div className="file-uploader">
            <input type="file" onChange={handleFileInput} accept="audio/*,video/*" className="hidden" />
            <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary"/>
        </div>
    )
}