import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraphImage(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    function convertBlob(b) {
        return new Promise(function(resolve, reject) {
            //console.log(b)
            var reader = new FileReader();   
            reader.readAsDataURL(b);
            reader.onloadend = function () {
                var base64String = reader.result;
                //console.log(base64String)
                resolve ({"data": base64String})
            }   
        })
    }

    return fetch(graphConfig.graphMeImageEndpoint, options)
    .then(response => response.blob()).then(b => convertBlob(b)).then(c => c)
    .catch(error => console.log(error));

}

export async function callMsGraphEmail(email, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    const url = graphConfig.graphEmailEndpoint + "/" + email; 
    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}