import React, {useEffect, useState} from 'react';
import api from '../../services/api';
import ReactHtmlParser from 'react-html-parser';
//import { refreshExpressToken } from '../../utils/getExpressToken';


const VideoShow = ({match}) => {

    const [content, setContent] = useState(null);
    const id = match.params.id;

    useEffect(() => {
        const getVideo = async () => {
            try {
                const {data} = await api.get(`/player?videoid=${id}`);
                setContent(data);
            } catch (err) {
                setContent("Not Authorized")
            }

        }
        //refreshExpressToken();  
        getVideo();
    }, [id])
    
    return(
        <div>
            <div className="p-4">{ReactHtmlParser(content)}</div>
        </div>
    )
}

export default VideoShow;