// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';


// THIS IS SAMPLE CODE ONLY - DON'T STORE TOKEN IN PRODUCTION CODE
//const sasToken = process.env.storagesastoken || ""; // Fill string with your SAS token

const containerName = `ingest`;

const env = process.env.REACT_APP_NODE_ENV || "development";
const storageAccountName = (env.toUpperCase() === "PRODUCTION") ? "voaindexingestsg" : "voaindexingestsguat"; 


// </snippet_package>


// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
/*export const isStorageConfigured = () => {
  return true
  //return (!storageAccountName || !sasToken) ? false : true;
}
*/
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
/*
const getBlobsInContainer = async (containerClient: ContainerClient) => {
  const returnedBlobUrls: string[] = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
*/
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
/*const createBlobInContainer = async (containerClient: ContainerClient, file: File, callback: any) => {
  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  // upload file
  await blobClient.uploadData(file, { onProgress: (ev) => callback(ev), blobHTTPHeaders: { blobContentType: file.type } });

  return blobClient
}*/
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
export const uploadFileToBlob = async (file: File | null, filename: string, sasToken: any, callback: any, metadata: any): Promise<String> => {
 

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  let blobClient;
  
  if (!file) return "";
  
  try {
    // upload file
    // create blobClient for container
    blobClient = containerClient.getBlockBlobClient(filename);

    // wait for this to complete
    await blobClient.uploadData(file, { onProgress: (ev) => callback(ev), blobHTTPHeaders: { blobContentType: file.type } });
    await blobClient.setMetadata(metadata);

  } catch (err) {
    return err
  }
  return blobClient.url
};
// </snippet_uploadFileToBlob>

// <snippet_uploadUrlToBlob>
export const uploadUrlToBlob = async (url: string | null, filename: string | null, sasToken: any, callback: any, metadata: any): Promise<String> => {

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  let blobClient;
  if (!url || !filename) return "";

  try {
    // upload file via URL
    // create blobClient for container
    blobClient = containerClient.getBlockBlobClient(filename);
    
    // start copy and set callback to update progress bar
    await blobClient.beginCopyFromURL(url, {
      intervalInMs: 1000, // poll blob every 1 second for copy progress
      onProgress(state) {
        callback(state.copyProgress);
        console.log(`Progress: ${state.copyProgress}`);  // not really using this
      }
    });

    await blobClient.setMetadata(metadata);


    // this will set progress bar to 100% and force page redirect
    callback("1/1")
  } catch (err) {
    return err
  }  

  return blobClient.url
};
// </snippet_uploadUrlToBlob>


