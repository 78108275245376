import React from 'react';
import { ReactComponent as Spinner } from './styles/icons/spinner-solid.svg';

export const Loading = () => {
  return (
    <div className='loading'>
      {/* <i className='fas fa-spinner' /> */}
      <Spinner />
      <h1>Loading...</h1>
    </div>
  );
};

export const Alert = ({ warning, item }) => {
  let alert = '';
  switch (warning) {
    case 'notfound':
      alert = item + ' not found!';
      break;
    case 'empty':
      alert = item + ' cannot be empty!';
      break;
    case 'match':
      alert = item + ' does not match!';
      break;
    case 'invalid':
      alert = item + ' you typed is invalid!';
      break;
    case 'server':
      alert = 'server failed to ' + item;
      break;
    default:
      break;
  }
  return <p className="text-red-500 text-xs italic">{alert}</p>;
};

// export default Loading;
