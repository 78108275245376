import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createUser, initUser, getPartitionData } from '../../actions/users';
import { setAlert } from '../../actions//alert';
import { Loading, Alert } from './utils';

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { callMsGraphEmail } from "../../graph"; 
import MultiSelect from "react-multi-select-component";

const CreateUser =  ({
  setAlert,
  createUser,
  alertContent,
  history,
  createSuccess,
  isLoading,
  error,
  getError,
}) => {
   //useEffect(() => initUser(), [initUser]); // may not need anymore
   
  const [userData, setUserData] = useState({
    email: '',
    fullname: '',
    id: '',
    roles: '',
    partitionData: new Array(0), 
    partitionOptions: new Array(0),
    message: ''
  });

  const { email, fullname, id, roles, partitionData, partitionOptions } = userData;

  useEffect(() => {
    getPartitionData().then(res => setUserData({ ...userData, partitionOptions: res }))
    // eslint-disable-next-line 
  },[])

  useEffect(() => {
    console.log("userdata:",userData);
    console.log("error:", error)
    console.log("error:",getError)
    // eslint-disable-next-line 
  },[userData,error])

  // const submitUser = async () => {
  //   let rs = await createUser({ firstname, lastname, sex, age, password });
  //   console.log(rs);
  //   if (rs === 'success') {
  //     initUser();
  //     history.push('/');
  //   } else {
  //     setAlert('Failed to create!');
  //   }
  // };

  const { instance, accounts } = useMsal();

  const handleChangePartition = (e) => {
    setUserData({ ...userData, partitionData:e });
  };

  const handleCreate = e => {
    e.preventDefault();
    console.log(userData)
    // convert partitionData to a simple array
    const partitions = partitionData.map(x => x.value)
    console.log(partitions)
    createUser({ email, fullname, "oid":id, "hash": "", roles, partitions });
    //console.log("i'm done:", error)

  };

  const handleChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleBack = () => {
    history.push('/listusers');
  };


  const handleLookup = e => {
    e.preventDefault();
    //setLookupClicked(true)
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      callMsGraphEmail(email, response.accessToken).then(
        (data) => {
          if (!data.error) {
            console.log("data:",data)
            setUserData({ ...userData,"fullname": data.displayName, "id":data.id });
           
            //setUserData({  "email": data.mail, "fullname": data.displayName, "id": data.id, "message": ''} );
          } else {
            setUserData({ ...userData, message: 'email not found' });
          }
        

        }
      )
    });

  }


  const disableLookup = (email) => {
    return !(
      email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    );
  };

  const disableCreate = (email, fullname, id) => {
    return !(
      email &&
      fullname &&
      id 
    );
  };

  return (
    <div>
      {createSuccess ? (
        <Redirect to='/listusers' />
      ) : isLoading ? (
        <Loading />
      ) : (
        <div className="relative flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100 bg-no-repeat bg-cover">
        <div className="absolute bg-white opacity-60 inset-0 z-0"></div>

        <div className=" w-full p-10 bg-white rounded-xl z-10">
          <div className="text-center">
              <h2 className="mt-5 text-3xl font-bold text-gray-900">
                Create User
              </h2>
              { /*<p className="mt-2 text-sm text-gray-400">Lorem ipsum is placeholder text.</p> */}
          </div>


      
          <div className="mt-8 flex w-full p-2 space-x-3 justify-center">  
            <form onSubmit={e => handleCreate(e)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <small className='form-text text-muted'>
                Blank with * is reuiqred
              </small>
              <div className='form-group'>
                * Email Address:{' '}
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'

                  name='email'
                  value={email}
                  onChange={e => handleChange(e)}
                  placeholder='email address'
                  disabled = {fullname ? true : false}
                />
                {!email && <Alert warning='empty' item='email' />}
                {email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email) && (
                  <Alert warning='invalid' item='email' />
                )}
                {!fullname && userData.message && <Alert warning='notfound' item='email' />}
              </div>

              { fullname ? 
              <div>
              <div className='form-group'>
                <label htmlFor='fullname'>* Full Name:{' '}</label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  name='fullname'
                  value={fullname}
                  
                  placeholder='fullname'
                  disabled
                />
              </div> 
              <div className='form-group'>
                <label htmlFor='role'>* Role:{' '}</label>
                <select
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  name='roles'
                  value={roles}
                  onChange={e => handleChange(e)}
                  placeholder='roles'
                >
                  <option></option>
                  <option>Admin</option>
                  <option>Contributor</option>
                  <option>Editor</option>
                  <option>Reader</option>
                  </select>
                {!roles && <Alert warning='empty' item='roles' />}
              </div>
            
              

              <div className='form-group'>
              <label >* Partition:{' '}</label>
              <MultiSelect
                options={partitionOptions}
                value={partitionData}
                onChange={e => handleChangePartition(e)}
                labelledBy="Select"
                isCreatable="true"
              />
              {!partitionData && <Alert warning='empty' item='partitionData' />}
              </div>
              {error && <Alert warning='server' item='create' />}
              {getError && <Alert warning='server' item='get' />}
              </div>
              : '' }


              <div className='btn-row'>

                { fullname ?


                <div className='btn-left'>
                  <button
                    className='btn btn-success'
                    // value='Submit'
                    type='submit'
                    disabled={
                      disableCreate(
                        email,
                       fullname,
                       id
                      )
                      // !(
                      //   firstname &&
                      //   lastname &&
                      //   sex &&
                      //   age &&
                      //   password &&
                      //   repeat &&
                      //   password === repeat
                      // )
                    }
                  >
                    <i className='fas fa-arrow-down' /> Add User
                  </button>
                </div> :
              <button className='btn btn-success' onClick={e => handleLookup(e)} disabled={
                disableLookup(email)}>
              <i className='fas fa-arrow-left' /> Lookup
            </button>
                  }

                <div className='btn-middle' />

                <div className='btn-right'>
                  <button className='btn btn-secondary' onClick={handleBack}>
                    <i className='fas fa-arrow-left' /> Back
                  </button>
                </div>
              </div>
            </form>
            <div >{alertContent}</div>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    alertContent: state.alert.alertContent,
    createSuccess: state.createUser.createSuccess,
    isLoading: state.createUser.isLoading,
    error: state.createUser.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alert => dispatch(setAlert(alert)),

    createUser: data => dispatch(createUser(data)),
    initUser: () => dispatch(initUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUser);
