import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph"; 

/**
 * Renders information about the user obtained from MS Graph
 * 
 */
export const ProfileData = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(
          (data) => {
            setGraphData(data)
          }
        )
      });
    }, [accounts, instance]);
    console.log(graphData)
    if (graphData) {

        return (
            <div id="profile-div">
                <p><strong>First Name: </strong> {graphData.givenName}</p>
                <p><strong>Last Name: </strong> {graphData.surname}</p>
                <p><strong>Email: </strong> {graphData.userPrincipalName}</p>
                <p><strong>Id: </strong> {graphData.id}</p>
            </div>
        );

    } else {
        return ('');
    }
};