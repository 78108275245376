import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";


export const SignInButton = () => {
    const { instance } = useMsal();
    
    const handleLogin = async (instance) => {

        sessionStorage.clear();
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    
        
    }
    return (
   
        <button className="uppercase font-bold block p-2 text-white text-sm sm:text-base md:text-lg rounded-full bg-voa-blue hover:bg-yellow-600 focus:outline-none" onClick={() => handleLogin(instance)}>Sign In</button>

    )
}