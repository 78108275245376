import React from "react";
//import Navbar from "react-bootstrap/Navbar";
import  logo  from '../assets/images/logo.svg';

//import { useIsAuthenticated } from "@azure/msal-react";
import { WelcomeName } from "./WelcomeName";

//import { Fragment } from 'react'
import { Disclosure, Menu } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { SignInButton } from "./SignInButton";
import { ProfileImage } from "./ProfileImage";
import { SignOutButton } from "./SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";
//import { AuthService } from "../services/auth.service"


/* can add more menu optoins here */
/* see here for examples:  https://tailwindui.com/components/application-ui/navigation/navbars */
const navigation = [
  {
    name: 'Home',
    href: '/'
  }, {
    name: 'Library',
    href: '#/library'
  }, {
    name: 'Upload',
    href: '#/upload'
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props) => {
//export class PageLayout extends React.Component {

  const isAuthenticated = useIsAuthenticated();

    return (
        <>
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center xs:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-1 flex items-center justify-start  sm:items-stretch">
                    <div className="flex-shrink-0 flex items-center">
                      <img
                        className="hidden xs:block h-8 w-auto"
                        src={logo}
                        alt="VOA Indexer"
                      />
                      <span className="hidden xs:block text-white"> Media Indexer</span>
                    </div>
                    {/* <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div> */}
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                    {/* notifications */}
                    {isAuthenticated ? ''
                      /* <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */
                      : ''}


                    {isAuthenticated ? <WelcomeName /> : <SignInButton />}
                    <span>&nbsp;&nbsp;</span>
                    {isAuthenticated ?  <SignOutButton /> : ''}
                    {/* Profile dropdown */}
                    {isAuthenticated ?                      
                      <Menu as="div" className="ml-3 relative">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                {isAuthenticated ? <ProfileImage /> : ''}
                              </Menu.Button>
                            </div>
                          </>
                        )}
                      </Menu>
                      : ''}
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-center text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </Disclosure.Panel>

            </>
          )}
        </Disclosure>
        {props.children}
        </>
    );
  
};
