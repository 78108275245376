import { useState, useRef, useEffect } from 'react'

import DatePicker from "react-datepicker";
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { queryOptions, languageOptions, partitionOptions } from './FilterData';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";


const Search = ({handleSearch}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [afterDate, setAfterDate] = useState(new Date('2021-05-01T00:00:00'));
    const [beforeDate, setBeforeDate] = useState(new Date());
    const [afterDateSelected, setAfterDateSelected] = useState(false);
    const [beforeDateSelected, setBeforeDateSelected] = useState(false);
    const [queryScope, setQueryScope] = useState({});
    const [sourceLanguage, setSourceLanguage] = useState({});
    const [partitions, setPartitions] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    const queryScopeRef = useRef();
    const sourceLanguageRef = useRef();
    const partitionsRef = useRef();

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 480);
      };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const handleSearchField = (searchInput) => {
        setSearchTerm(searchInput);
        handleSearch(`query=${searchInput}${filterQueryBuilder()}`);
    };

    const clearSearchInput = () => {
        if(searchTerm !== '') {
            setSearchTerm('');
            handleSearch('', true);
        }
    }

    const refreshSearch = () => {
        if(searchTerm !== '') {
            handleSearch(`query=${searchTerm}${filterQueryBuilder()}`)
            setFiltersVisible(false);
        } else {
            handleSearch(`${filterQueryBuilder()}`)
            setFiltersVisible(false);
        }
    }

    const handleQueryScopeFilter = (chosenValue) => {
        const value = chosenValue === null ? '' : chosenValue;
        setQueryScope(value);
    }

    const handleSourceLanguageFilter = (chosenValue) => {
        const value = chosenValue === null ? '' : chosenValue;
        setSourceLanguage(value);
    }

    const handlePartitionFilter= (chosenValues) => {
        let arr = [];
        chosenValues.forEach((v) => {
            arr.push(v);
        });
        setPartitions(arr); 
    }

    const handleCreatedAfter = (chosenDate) => {
        setAfterDate(chosenDate);
        setAfterDateSelected(true);
    }

    const handleCreatedBefore = (chosenDate) => {
        setBeforeDate(chosenDate);
        setBeforeDateSelected(true);
    }

    // Build query using state variables
    const filterQueryBuilder = () => {
        let query = '';
        if(!_.isEmpty(queryScope)) {
            query += `&textScope=${queryScope.value}`;
        }
        if(afterDateSelected) {
            query += `&createdAfter=${afterDate.toISOString()}`;
        }
        if(beforeDateSelected) {
            query += `&createdBefore=${beforeDate.toISOString()}`;
        }
        if(!_.isEmpty(sourceLanguage)) {
            query += `&sourceLanguage=${sourceLanguage.value}`;
        }
        if(partitions.length !== 0) {
            let partitionQuery = '';
            partitions.forEach((partition) => {
                partitionQuery += `&partition=${partition.value}`
            })
            query += partitionQuery;
        }

        return query;
    }

    const clearFilters = () => {
        setAfterDate(new Date('2021-05-01T00:00:00'));
        setBeforeDate(new Date());
        setQueryScope({});
        queryScopeRef.current.select.clearValue();
        setSourceLanguage({});
        sourceLanguageRef.current.select.clearValue();
        setPartitions([]);
        partitionsRef.current.select.clearValue();
    }

    const showFilters = () => {
        if(filtersVisible) {
            return(
                <div className={`absolute transition w-full ${isMobile ? 'flex flex-col' : 'grid grid-cols-4'} items-center gap-4 bg-gray-50 z-10 mt-14 p-4 rounded-b-2xl border-2`}>
                    <span className="row-start-1 col-start-1 justify-self-start pl-1 sm:pl-12">Insight</span>
                    <div className="row-start-1 sm:col-start-2 w-full">
                        <Select isClearable options={queryOptions} {... !_.isEmpty(queryScope) ? {defaultValue: queryScope} : {}} 
                            ref={queryScopeRef} onChange={(e) => handleQueryScopeFilter(e)} />
                    </div>
                    <span className="row-start-2 col-start-1 justify-self-start pl-1 sm:pl-12">Language</span>
                    <div className="row-start-2 col-start-2 w-full">
                        <Select isClearable options={languageOptions} {... !_.isEmpty(sourceLanguage) ? {defaultValue: sourceLanguage} : {}} 
                            ref={sourceLanguageRef} onChange={(e) => handleSourceLanguageFilter(e)}  />
                    </div>
                    <span className="row-start-3 col-start-1 justify-self-start pl-1 sm:pl-12">Partition</span>
                    <div className="row-start-3 col-start-2 w-full">
                        <Select isMulti options={partitionOptions()} {... !_.isEmpty(partitions) ? {defaultValue: _.each(partitions, (p, i) => {return partitions[i]})} : []} 
                             ref={partitionsRef} value={partitions} onChange={(e) => handlePartitionFilter(e)} />
                    </div>
                    <span className="row-start-1 col-start-3 justify-self-start pl-1 sm:pl-12">Created After</span>
                    <div className="row-start-1 col-start-4 w-full"><DatePicker className="rounded w-full" selected={afterDate} 
                        onSelect={(date) => handleCreatedAfter(date)} />
                    </div>
                    <span className="row-start-2 col-start-3 justify-self-start pl-1 sm:pl-12">Created Before</span>
                    <div className="row-start-2 col-start-4 w-full"><DatePicker className="rounded w-full" selected={beforeDate} 
                            onSelect={(date) => handleCreatedBefore(date)} />
                    </div>
                    <div className="row-start-3 col-start-3 col-span-2 lg:col-span-1 lg:col-start-4 flex justify-end lg:justify-center">
                        <button data-tip="Filter results" type="submit" className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 mr-4 rounded" 
                            onClick={(e) => refreshSearch()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button data-tip="Clear filters" type="submit" className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 mr-4 rounded" 
                            onClick={(e) => clearFilters()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clipRule="evenodd" />
                            </svg>
                        </button> 
                        <button data-tip="Close filter panel" type="submit" className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded" 
                            onClick={() => setFiltersVisible(!filtersVisible)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <ReactTooltip place="top" type="dark" effect="float"/>
                    </div>
                </div>
            )
        }
    }

        return(
                <div className="relative flex flex-col w-full focus-within:ring-1">
                    <div className="flex flex-row w-full border-2 rounded">
                        <input type="text" placeholder="Search" value={searchTerm} id="search" onChange={(e) => handleSearchField(e.target.value)}
                        className="px-3 py-3 scale-100 bg-gray-50 text-lg border-0 rounded-t w-full focus:ring-0"/>
                        <button data-tip="Clear search" type="submit" className="bg-gray-50 pr-4 text-gray-500 hover:text-gray-800" onClick={() => clearSearchInput()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        
                        <button data-tip={filtersVisible ? 'Close filter panel' : 'Open filter panel'} type="submit" className="bg-gray-50 pr-4 text-gray-500 hover:text-gray-800" onClick={() => setFiltersVisible(!filtersVisible)}>
                            {filtersVisible ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                            </svg>}
                        </button>
                        <ReactTooltip place="top" type="dark" effect="float"/>
                    </div>
                    {showFilters()}
                </div> 
        );
}

export default Search;