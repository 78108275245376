import React, { useEffect} from 'react';
import logo2 from '../assets/images/logo2.png';
//import { getWithExpiry } from "../utils/sessionWithExpiry"
import { UnauthenticatedTemplate, AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
//import { SignInButton } from './SignInButton';
//import { getExpressToken } from "../utils/getExpressToken";
import { login } from "../actions/auth"
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from 'react-router-dom';



const Home = (props) => {
  console.log("rendering Home for "+process.env.NODE_ENV);

  // this isAuthenticated is from the MSAL.  Once we authenticate with the express server, we will set another flag in App component
  const isAuthenticated = useIsAuthenticated();

  // let's keep state on the role in this component and when it get's updated from the express server, it will render
  //const [userRole, setUserRole] = useState(getWithExpiry("userrole"));

  const { isLoggedIn, user } = useSelector(state => state.auth);
  //const { message } = useSelector(state=> state.message);
  const store = useStore()
  const history = useHistory();
  const dispatch = useDispatch();
  console.log("MSAL:",isAuthenticated)
  console.log("Express:",isLoggedIn)
  console.log("Store:",store.getState())
  console.log("User:",user)

  useEffect(() => {  
  if (isAuthenticated && !(isLoggedIn)) {
    dispatch(login())
        .then(() => {
          //props.history.push("/#");
          //history.push("/#");
          //window.location.href="/#/auth-received";

        })
        .catch(() => {
          //console.log(message)
        });
  }
}, [isAuthenticated, isLoggedIn, dispatch, history]);


  return (
    <>
      <AuthenticatedTemplate>
        <div className="min-h-full bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 sm:max-w-md sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-voa-dark-blue bg-voa-light-blue shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
            <div className="relative px-4 py-6 bg-gray-800 shadow-lg sm:rounded-3xl sm:p-10">
              <div className="max-w-sm mx-auto">
              <div className=" flex items-center object-top ">
                <img 
                    className="block pt-4 lg:hidden object-contain h-16 w-full"
                    src={logo2}
                    alt="VOA Logo"
                  />
                <img
                    className="hidden lg:block pt-4 object-contain h-16 w-full"
                    src={logo2}
                    alt="VOA Logo"
                  />
                  
                </div>
                
                  <div className="py-8 text-2xl leading-6 space-y-4 text-gray-700 sm:text-xl sm:leading-7">
                  <h2 className="text-xl font-bold text-center text-gray-200">Logged in to Media Indexer</h2>
                </div>
                <h2 className="text-xl font-bold text-center text-gray-200">SSO authenticated</h2>
                <h2 className="text-xl font-bold text-center text-gray-200">Current Role ({user ? user.roles : ''})</h2>
                  <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                  <h2 className="text-xl font-bold text-center text-gray-200">More features coming soon...</h2>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="bg-mic min-h-full bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            
          <div className="mt-2 bg-gradient-to-r from-gray-800 to-gray-700 max-w-sm mx-auto rounded-xl shadow-2xl overflow-hidden items-center z-10">
          <div className=" flex items-center object-top ">
                <img 
                    className="block pt-4 lg:hidden object-contain h-16 w-full"
                    src={logo2}
                    alt="VOA Logo"
                  />
                <img
                    className="hidden lg:block pt-4 object-contain h-16 w-full"
                    src={logo2}
                    alt="VOA Logo"
                  />
                <div>
                
                </div>
            </div>
            <form className="p-6 space-y-10">
                    <h2 className="text-3xl font-bold text-center text-white">Welcome to Media Indexer</h2>
                        <div className="flex items-center justify-center mt-4">
                        
                        
                        </div>
                </form>    
            </div>
          </div>
          <div className="absolute bottom-20 right-20 text-md font-bold text-center text-white" >Brought to you by OCDO</div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

/*const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
   // setAlert: alert => dispatch(setAlert(alert)),
   // createUser: data => dispatch(createUser(data)),
   // initUser: () => dispatch(initUser())
   login: () => dispatch(login())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
*/
export default Home;
