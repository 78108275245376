import VideoCard from './VideoCard';
import api from '../../services/api';
import { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'


const VideoList = ({data, imageLoaded}) => {

    let [isOpen, setIsOpen] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
    let [modalText, setModalText] = useState('');
    let [videoId, setVideoId] = useState('');
    let [videoAction, setVideoAction] = useState('');

    const videoActionToTake = (action, videoid) => {
        setVideoId(videoid);
        if(action === 'delete') {
            setModalTitle('Delete Video?');
            setModalText('Are you sure you want to delete this video?');
            setVideoAction('delete');
        } else {
            setModalTitle('Reindex Video?');
            setModalText('Are you sure you want to reindex this video?');
            setVideoAction('reindex');
        }
        openModal();
    }

    const confirmVideoAction = async() => {
        let res = {};
        if(videoAction === 'delete') {
            res = await api.get(`deleteVideo?videoid=${videoId}`);
            console.log('video deleted');
        } else if(videoAction === 'reindex') {
            res = await api.get(`reIndex?videoid=${videoId}`);
            console.log('video reindexed');
        } 
        closeModal();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        return res;   
    }    

    const closeModal = () => {
        setIsOpen(false)
      }
    
    const openModal = () => {
        setIsOpen(true)
      }
    

    const returnCards = data.map((result) => {
        return(
            <VideoCard 
                key={result.id}
                id={result.id}
                name={result.name}
                uploadedBy={result.metadata}
                thumbnailId={result.thumbnailId}
                processingProgress={result.processingProgress }
                imageLoaded={imageLoaded}
                videoActionToTake = {videoActionToTake}
            />
        )
    });

    if(data.length === 0) {
        return <p>No Results Found...</p>
    } else {
        return(
            <>
                <div className="overflow-y-auto"> 
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                        {data !== undefined ? returnCards : null}
                    </div>
                </div>  
                
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                    >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                            >
                            {modalTitle}
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">{modalText}</p>
                            </div>

                            <div className="mt-4 flex justify-around">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-voa-blue border border-transparent rounded-md hover:bg-voa-light-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-voa-blue border border-transparent rounded-md hover:bg-voa-light-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={confirmVideoAction}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>  
            </>
            
        );
    }
}

export default VideoList;