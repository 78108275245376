import { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';


const ProjectList = () => {

    const [projectResults, setProjectResults] = useState([]);
    const [query] = useState('/search?isBase=false')
    const history = useHistory();

    useEffect(() => {
        let mounted = true;
        const getProjects = async () => {
            if(mounted) {
                const {data} = await api.get(`${query}`);
                setProjectResults(data.results);
                return data.results;
            }
        }

        getProjects();
        mounted = false;
    }, [query]);

    const routeChange = () => {
        const path = '/newproject';
        history.push(path);
    }

    const returnProjects = projectResults.map((project) => {
        return(    
            <ProjectCard 
                key = {project.id}
                id = {project.id}
                name = {project.name}
                userName = {project.userName}
                thumbnailVideoId = {project.thumbnailVideoId}
                thumbnailId = {project.thumbnailId}
                lastModified = {project.lastModified}
            />
        );
    });

    return(
        <>
            <div className="flex">
                <button className="bg-voa-blue hover:bg-yellow-600 text-white font-bold py-2 px-4 ml-3 mt-4 rounded"
                onClick={routeChange}>Create a new project</button>
            </div>
            <div className="m-2 grid grid-cols-4">
                <span className="col-start-2">Name</span>
                <span className="col-start-3">Indexed by</span>
                <span className="col-start-4">Last modified</span>
                {returnProjects}
            </div>
        </>
);
}

export default ProjectList;