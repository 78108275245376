import React from 'react';
//import { ArrowUpIcon } from '@heroicons/react/outline'

const Table = ({
  queryCur,
  sortType,
  users,
  actAttr,
  activePage,
  maxRowsPerPage,
  activeUser,
  handleDelete,
  handleEdit,
  handleSort
}) => {
  const displayUser = (
    queryCur,
    sortType,
    users,
    actAttr,
    activePage,
    maxRowsPerPage
  ) => {
    return activeUser(queryCur, sortType, users, actAttr).slice(
      (activePage - 1) * maxRowsPerPage,
      activePage * maxRowsPerPage
    );
  };

  const showOrder = attr => {
    return (
      actAttr === attr &&
      (sortType === 1 ? (
        <svg xmlns="http://www.w3.org/2000/svg" tabIndex="0" className="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
</svg>
      ) : (
        sortType === 2 && <svg xmlns="http://www.w3.org/2000/svg" tabIndex="0" className="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
      </svg>
      ))
    );
  };
  

  return (
<div className="table w-full p-2">
        <table className="w-full border">
            <thead>
                <tr className="bg-gray-50 border-b">

                    <th scope="col" className="p-2 border-r cursor-pointer text-sm text-gray-500" tabIndex="0" id='fullname' onClick={e => handleSort(e)}>
                       
                            Name {showOrder('fullname')}
                         
                    </th>
                    <th scope="col" className="p-2 border-r cursor-pointer text-sm  text-gray-500" tabIndex="0" id="email" onClick={e => handleSort(e)}>
                        
                            Email {showOrder('email')}
                            
                        
                    </th>
                    <th className="p-2 border-r cursor-pointer text-sm  text-gray-500" >
                        <div className="flex items-center justify-center">
                            Role
                        </div>
                    </th>
                    <th className="p-2 border-r cursor-pointer text-sm  text-gray-500" >
                        <div className="flex items-center justify-center">
                            Partitions
                        </div>
                    </th>                    
                    <th className="p-2 border-r cursor-pointer text-sm  text-gray-500">
                        <div className="flex items-center justify-center">
                            Action
  
                        </div>
                    </th>
 
                </tr>
            </thead>
            <tbody>
                

                {displayUser(
            queryCur,
            sortType,
            users,
            actAttr,
            activePage,
            maxRowsPerPage
          ).map(user => {
            return (
              <tr className="bg-gray-100 text-center border-b text-sm text-gray-600" key={user.oid}>

                <td className="p-2 border-r text-left">
                  <div className='table-data'>{user.fullname}</div>
                </td>
                <td className="p-2 border-r text-left">
                  <div className='table-data'>{user.email}</div>
                </td>
                <td className="p-2 border-r text-left">
                  <div className='table-data'>{user.roles}</div>
                </td>
                <td className="p-2 border-r text-left">
                  <div className='table-data'>{user.partitions.toString()}</div>
                </td>
                <td>
                <div className="inline-flex">
                <button onClick={e => handleEdit(user.oid)} href="#" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 roundedbtn btn-outline-succes">Edit</button>&nbsp;&nbsp;
                <button onClick={e => handleDelete(user.oid)} href="#" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 roundedbtn btn-outline-succes">Remove</button>
                </div>
                </td>

              </tr>
            );
          })}

            </tbody>
        </table>
    </div>






    
  );
};

export default Table;
