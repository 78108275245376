import React, { useState, useCallback, useEffect } from 'react';

import { FileUploader } from "./FileUploader"

import {uploadFileToBlob, uploadUrlToBlob} from './azure-storage-blob.ts';
import {useDropzone} from 'react-dropzone'
import { ProgressBar } from "./ProgressBar";
import { Redirect } from 'react-router-dom';
import validUrl from 'valid-url';
//import { getWithExpiry } from '../../utils/sessionWithExpiry';
import path from 'path'
import url from 'url'
//import { refreshExpressToken } from '../../utils/getExpressToken';
import api from '../../services/api';
import tokenService from '../../services/token.service';
import EventBus from '../../utils/EventBus'

const UploadFile = () => {
    // eslint-disable-next-line 
    const [selectedFile, setSelectedFile] = useState(null);
    const [urlFile, setUrlFile ] = useState("");
    const [progressBarPct, setProgressBarPct] = useState(0)
    const [fileuploading, setFileUploading] = useState(false)
    const [returnToDashboard, setReturnToDashboard ] = useState(false) 
    const [fileUploadError, setFileUploadError] = useState(false) 

    // TODO later when displaying the Upload File URL
    const [toggleForm, setToggleForm] = useState(false);
    const [toggleUrlFile, setToggleUrlFile] = useState(false);
    const [validUrlFile, setValidUrlFile] = useState(false);
    
    const data = tokenService.getUser();
    //console.log(data)

    var partitionOptions = [];
    data.partitions.forEach((partition) => {
        partitionOptions.push({ 
             "label" : partition,
             "value"  : partition
         });
     })

     const [name, setName] = useState("");
     const [language, setLanguage] = useState("auto");
     const [description, setDescription] = useState("");
     const [externalid, setExternalID] = useState("");
     const [partition, setPartition] = useState(data.partitions[0]);
     const [access, setAccess] = useState(false);

    // drag and drop code
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            setSelectedFile(file);
            setName(file.name);
          // Do whatever you want with the file contents
           // const binaryStr = reader.result
           // console.log(binaryStr)
          }
          reader.readAsArrayBuffer(file)
        })
        
      }, [])
      const {getRootProps} = useDropzone({onDrop})


     // TODO for later  
     const uploadFromUrl = (event) => {
        event.preventDefault()  
        setToggleForm(true)
        setToggleUrlFile(true)
    }    

    const changeFileUrl = (urlString) => {
        if (validUrl.isUri(urlString)){
            //const myUrl = new URL(urlString)
            const parsed = url.parse(urlString)
            console.log(parsed)
            setUrlFile(parsed.href)
            const filename=path.basename(parsed.pathname)
            console.log(filename)
            setName(filename)
            setValidUrlFile(true)
            
        } else {
            console.log("failing")
            setUrlFile(urlString)
            setValidUrlFile(false)
        }
    }

    // callback function to compute the pct complete
    // problem is that since using the setProgressBarPct, if it is not mounted will throw an error
    const progressCallback = (ev) => {
        try {
            setProgressBarPct((ev.loadedBytes/selectedFile.size)*100)
        } catch (err) {
            console.log(err)
        }
        
        //if (progress === 1) setReturnToDashboard(true)
    }

    const progressCallbackUrl = (ev) => {
        try {
            let progress = parseFloat(ev.split("/")[0]/ev.split("/")[1])
            setProgressBarPct(progress*100)
        } catch (err) {
            console.log(err)
        }
        //if (progress === 1) setReturnToDashboard(true)
    }

    // reset the form and state
    const resetForm = (event) => {
        event.preventDefault()
        window.location.reload();
    }

    // this function will upload the file to azure storage
    const submitForm = async (event) => {
        event.preventDefault()

        let result;

        // only submit when there is file to upload OR valid URL to process
        if (toggleUrlFile && !validUrlFile) return false;
        if (!toggleUrlFile && !selectedFile) return false;

        // get sasToken

        const sasToken =  await api.get("/sastoken");
        //console.log("sastoken",sasToken)
        // this will display the progress bar
        setFileUploading(true)

        // upload file directly to Azure
        try {
        
            //const data = getWithExpiry("data")
            
            //console.log(data);
            let email_data = '';
            let fullName_data='';
            
            if (data) { 
                email_data = data.email;
                fullName_data = data.fullname; 
            }
        
            const metadata = {
                email: email_data,
                language : language,
                fullName  : fullName_data,
                partition : partition, 
                description : description, 
                externalid : externalid
              };
           // console.log("Sending metadata:",metadata)

            if (selectedFile) {
                result =  await uploadFileToBlob(selectedFile,name,sasToken.data,progressCallback,metadata);
            }

            if (validUrlFile) {             
                result =  await uploadUrlToBlob(urlFile, name, sasToken.data,progressCallbackUrl,metadata);
            }

            if (!result || result.includes("Error")) {
                setFileUploadError(true)
                setFileUploading(false)
                return false;
            }

            // That's it.  Just upload to blob storage and let LOGIC APP handle the rest.
            setTimeout(() => {  setReturnToDashboard(true) }, 2000);

        }  catch (err) {
            console.log(err)
            setFileUploadError(true)
            //setFileUploading(false)
        }
      }; 

    useEffect(() => {
        const checkAccess = async () => {
            console.log("inside checkAccess")
            return await api.get(`/uploadcheck`)
             .then( res => {
                console.log("res partitions:",res.status);
                return (res.status === 200 ? true : false)
             })
            .catch(err => {
                if (err.response && err.response.status === 403.1) {
                    EventBus.dispatch("logout");
                }
                console.log(err)
                return err
            }); 

        }        

        checkAccess().then(res => {console.log("result:",res); setAccess(res)});

    }, [])
    
    
    // change the route to "/upload" to allow access


    if (returnToDashboard) {
        return <Redirect to='/library' />
    } else {
        return(
      
        <div {...getRootProps()} className="h-full">

            { access ? 
            <div className="relative h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100 bg-no-repeat bg-cover">
                <div className="absolute bg-white opacity-60 inset-0 z-0"></div>
                <div className="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
                    <div className="text-center">
                        <h2 className="mt-5 text-3xl font-bold text-gray-900">
                         {!toggleUrlFile ?    "Upload your media file" : "Upload from URL"}</h2>
                        { /*<p className="mt-2 text-sm text-gray-400">Lorem ipsum is placeholder text.</p> */}
                    </div>
                    
                    <form className="mt-8 space-y-3" action="#" method="POST">
                        { toggleForm || selectedFile ? 
                        <div>
                            { toggleUrlFile ? 
                            <div className="grid grid-cols-1 space-y-2">
                                <label htmlFor="videoUrlFile" className="text-sm font-bold text-gray-700 tracking-wide text-left">Enter URL</label>
                                <input value={urlFile}
                                    onChange={(e) => changeFileUrl(e.target.value)} 
                                    id="videoUrlFile" name="videoUrlFile" placeholder="For example, a OneDrive file URL"
                                    className="text-base  p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" 
                                    type="text" />
                            </div>
                            : ""}
                        <div className="grid grid-cols-1 space-y-2">
                            <label htmlFor="videoUploadName" className="text-sm font-bold text-gray-700 tracking-wide text-left">Name</label>
                            <input value={name}
                                onChange={(e) => setName(e.target.value)} 
                                id="videoUploadName" name="videoUploadName" 
                                className="text-base p-2  border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" 
                                type="text" />
                        </div>
                        <div className="grid grid-cols-1 space-y-2">
                            <label htmlFor="partition" className="text-sm pt-2 font-bold text-gray-700 tracking-wide text-left">Storage Partition</label>
                            <select id="partition" name="partition" 
                                onChange={(e) => setPartition(e.target.value)} 
                                value={partition}
                                className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" >
                                {partitionOptions.map((part) => <option key={part.value} value={part.value}>{part.label}</option>)}
                            </select>
                        </div>
                        <div className="grid grid-cols-1 space-y-2">
                            <label htmlFor="videoSourceLanguage" className="pt-2 text-sm font-bold text-gray-700 tracking-wide text-left">Source Language</label>
                            <select id="videoSourceLanguage" name="videoSourceLanguage" 
                                onChange={(e) => setLanguage(e.target.value)} 
                                value={language}
                                className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" >
                                <option value="auto">Auto-detect single</option>
                                <option value="multi">Auto-detect multi</option>
                                <option value="ar-SA">Arabic (Saudi Arabia)</option>
                                <option value="ar-SY">Arabic Syrian Arab Republic</option>
                                <option value="ar-PS">Arabic (Palestinian Authority)</option>
                                <option value="ar-QA">Arabic (Qatar)</option>
                                <option value="ar-EG">Arabic Egypt</option>
                                <option value="ar-BH">Arabic Modern Standard (Bahrain)</option>
                                <option value="ar-OM">Arabic (Oman)</option>
                                <option value="ar-LB">Arabic (Lebanon)</option>
                                <option value="ar-AE">Arabic (United Arab Emirates)</option>
                                <option value="ar-KW">Arabic (Kuwait)</option>
                                <option value="ar-JO">Arabic (Jordan)</option>
                                <option value="ar-IQ">Arabic (Iraq)</option>
                                <option value="ar-IL">Arabic (Israel)</option>
                                <option value="zh-HK">Chinese (Cantonese Traditional) </option>
                                <option value="zh-Hans">Chinese (Simplified)</option>
                                <option value="cs-CZ">Czech</option>
                                <option value="da-DK">Danish</option>
                                <option value="nl-NL">Dutch</option>
                                <option value="en-US">English United States</option>
                                <option value="en-GB">English United Kingdom</option>
                                <option value="en-AU">English Australia</option>
                                <option value="fi-FI">Finnish</option>
                                <option value="fr-CA">French (Canada)</option>
                                <option value="fr-FR">French</option>
                                <option value="de-DE">German</option>
                                <option value="hi-IN">Hindi</option>
                                <option value="it-IT">Italian</option>
                                <option value="ja-JP">Japanese</option>
                                <option value="ko-KR">Korean</option>
                                <option value="nb-NO">Norwegian</option>
                                <option value="pl-PL">Polish</option>
                                <option value="pt-BR">Portuguese</option>
                                <option value="ru-RU">Russian</option>
                                <option value="es-ES">Spanish</option>
                                <option value="es-MX">Spanish (Mexico)</option>
                                <option value="sv-SE">Swedish</option>
                                <option value="tr-TR">Turkish</option>
                                <option value="th-TH">Thai</option>
                            </select>
                        </div>
                        <div className="grid grid-cols-1 space-y-2">
                        <label htmlFor="videoDescription" className=" pt-2 text-sm font-bold text-gray-700 tracking-wide text-left">Description</label>
                        <input 
                            value={description}
                            type="textarea" 
                            id="videoDescription" 
                            name="videoDescription"
                            onChange={(e) => setDescription(e.target.value)}
                            className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"  
                            
                        />
                        </div>
                        <div className="grid grid-cols-1 space-y-2">
                        <label htmlFor="externalID" className=" pt-2 text-sm font-bold text-gray-700 tracking-wide text-left">External ID</label>
                        <input 
                            value={externalid}
                            type="textarea" 
                            id="externalID" 
                            name="externalID"
                            onChange={(e) => setExternalID(e.target.value)}
                            className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"  
                            
                        />
                        </div>
                        </div>
                        : ""}
                        {!(selectedFile || toggleUrlFile) ?
                            <div className="grid grid-cols-1 space-y-2">
                                <label className="text-sm font-bold text-gray-700 tracking-wide"></label>
                                <div className="flex items-center justify-center w-full">
                                    <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                                        <div className="h-full w-full text-center flex flex-col items-center justify-center cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-blue-400 group-hover:text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                            </svg>
                                            <div className="hidden xs:block flex flex-auto max-h-48 w-2/5 mx-auto -mt-10">
                                                <img className="has-mask h-36 object-center" src="https://img.freepik.com/free-vector/image-upload-concept-landing-page_52683-27130.jpg?size=338&ext=jpg" alt="freepik" />
                                            </div>
                                            <p className="hidden xs:block pointer-none text-gray-500 ">
                                                Drag and drop files here
                                                <br /> or CLICK to select a file from your computer
                                            </p>
                                            <p className="block xs:hidden pointer-none text-gray-500 ">
                                                CLICK to select a file from your mobile device
                                            </p>
                                        </div>
                                      
                                        <FileUploader
                                            onFileSelectSuccess={(file) => { setSelectedFile(file); setName(file.name) }}
                                            onFileSelectError={({ error }) => alert(error)}
                                        />
          
                                    </label>

                                </div>
                            
                                or <button onClick={uploadFromUrl} className="my-2 w-full flex justify-center bg-blue-500 text-gray-100 p-2  rounded-full ">Upload from URL</button>
    
                            </div>
                            : ""}
                        {/*<p className="text-sm text-gray-300">
                                <span>File type: mp4, mp3, wav, avi</span>
                            </p>*/}
                        { selectedFile || toggleForm ?     
                        <div>
                            <button onClick={submitForm} type="submit" className="float-left bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Upload
                            </button>
                            <button onClick={resetForm} className="float-right bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Cancel
                            </button>
                        </div>
                        : ""}
                    </form>
                    { 
                    // display the progress bar when uploading a file
                    fileuploading ?
                        <div>
                            <br/><br/>
                            <ProgressBar pct={progressBarPct} />
                        </div>
                    : ""}
                    {
                    // display any file upload error message
                    fileUploadError ?
                        <div>
                        <br/><br/>
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <span className="block sm:inline">There was a problem uploading the file</span>
                        </div>
                        </div>
                    : ""}    
                </div>
            </div>
            : "Not Authorized" }


        </div>
       
    );
    }
}

export default UploadFile;