import React from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from 'react-router-dom';


export const SignOutButton = () => {
    const { instance, } = useMsal();
    const history = useHistory();
    
    const handleLogout = async (instance) => {

        // let's refresh the window in about 1/2 second
        setTimeout(() => {   window.location.reload() }, 500);
        sessionStorage.clear()
        localStorage.clear();
        history.push("/");
        //const currentAccount = instance.currentAccount
        //console.log(currentAccount)
        /*instance.logoutRedirect({
            account: currentAccount,
            postLogoutRedirectUri: "https://contoso.com/loggedOut"
        });*/
/*
        const logoutRequest = {
            account: accounts[0],
            mainWindowRedirectUri: "http://localhost:3000",
            //postLogoutRedirectUri: "http://localhost:3000"
        }
        */


        //instance.logoutRedirect(logoutRequest).then(window.close);
        //window.location.reload()
        
    }
    return (
   
        <button className="uppercase font-bold block p-2 text-white text-sm sm:text-base md:text-lg rounded-full bg-voa-blue hover:bg-yellow-600 focus:outline-none" onClick={() => handleLogout(instance)}>Sign Out</button>

    )
}