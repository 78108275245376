import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

export const WelcomeName = () => {
    const { accounts } = useMsal();
    const [name, setName] = useState(null);

    useEffect(() => {
        if (accounts.length > 0) {
           // console.log(accounts)
            setName(accounts[0].name.split(" ")[0]);
        }
    }, [accounts]);

    if (name) {
        return <h6 className="hidden xs:block text-white">Welcome: {name} </h6>;
    } else {
        return null;
    }


};
