import React from 'react';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import '../../assets/css/VideoCard.css';
import _ from 'lodash';


 const VideoSearchResultCard = ({id, name, uploadedBy, createdDate, thumbnailId, processingProgress, searchMatches, searchTerm, imageLoaded}) => {

    const [imageUrl, setImageUrl] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [reducedSearchResults, setReducedSearchResults] = useState({});
    const [searchTermInTitle, setSearchTermInTitle] = useState(false);
    const inProgress = (processingProgress !== '100%') ? true : false;

    useEffect(() => {
        let mounted = true;
        const getThumbnail = async () => {
            if(mounted) {
                const {data}= await api.get(`/thumbnail?videoid=${id}&thumbnailid=${thumbnailId}`, {
                    responseType: 'blob',
                    timeout: 30000
                });
                setImageUrl(URL.createObjectURL(data));
            }    
        }
        
        // This function combines the array of searchResults objects into one object organized by searchMatch type.
        // Also, transforms some of the data to make it more presentable.  Additional 'types' can be added
        const combineAndTransformSearchResults = _.reduce(searchMatches, (result, record) => {
            if(record.type === "Transcript") {
                (result[record.type] || (result[record.type] = [])).push(record)
            }
            if(record.type === "Topic") {
                (result[record.type] || (result[record.type] = [])).push(record)
            }        
            if(record.type === "Face") {
                (result[record.type] || (result[record.type] = [])).push(record)
            }    
            if(record.type === "NamedPerson") {
                record.type = "Named Person";
                (result[record.type] || (result[record.type] = [])).push(record)
            }
            if(record.type === "Ocr") {
                record.type = "On screen text";
                (result[record.type] || (result[record.type] = [])).push(record)
            }
            if(record.type === "Title") {
                setSearchTermInTitle(true);
            }
            
            return result;
        }, {});

        getThumbnail();
        
        if(!_.isEmpty(combineAndTransformSearchResults)) {
            setReducedSearchResults(combineAndTransformSearchResults)
        }

        // cleanup function
        return () => mounted = false;
    }, [id, thumbnailId, searchMatches]);

    // Helper method that highlights the search term in the searchResult text.
    const getHighlightedText = (text, termToHighlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${termToHighlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} className={part.toLowerCase() === termToHighlight.toLowerCase() ? 'bg-voa-very-light-blue px-0.5 py-1' : {} }>
                { part }
            </span>)
        } </span>;
    }

    const returnCreationDate = () => {
        let date = new Date(createdDate);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    
    const returnSearchMatches = Object.keys(reducedSearchResults).map((keyname, i) => {

        const returnTimestamps = Object.keys(reducedSearchResults[keyname]).map((k, i) => {
            const item = reducedSearchResults[keyname][k];
            return(
                <div key={i} className="py-1">{item.startTime.substring(0, 8)}</div>     
            );
        })

        const returnText = Object.keys(reducedSearchResults[keyname]).map((k, i) => {
            const item = reducedSearchResults[keyname][k];
            return(
                <div key={i} className="py-1">{getHighlightedText(item.text, searchTerm)}</div> 
            );
        });

        return(
            <div className="grid grid-col-2 gap-x-8 justify-items-start" key={i}>
                <div className="grid-item col-start-2 py-2"><strong>{keyname}</strong></div>
                <div className="grid grid-flow-row min-w-20">
                    {returnTimestamps}
                </div>
                <div className="grid grid-flow-row col-start-2 justify-items-start">
                    {returnText}
                </div>
            </div>
        );
        
    });
    
    return(
        <div className="border border-b">
            <div className=" flex flex-row cursor-pointer justify-between items-center">
                <Link to={inProgress ? '#' : `/video/${id}`} className="stretched-link text-decoration-none flex overflow-hidden">
                    <div className="flex flex-grow hover:bg-gray-200 items-center">
                        <div className="min-w-28"><img className="max-h-16 p-1" src={imageUrl} onLoad={imageLoaded} alt={`Thumbnail of ${name}`} /></div>
                        <div className="flex flex-col items-start pl-4">
                            <p className="text-sm sm:text-base md:text-lg lg:text-xl">{searchTermInTitle === true ? getHighlightedText(name, searchTerm) : name}</p>
                            <p className="hidden xs:block text-sm md:text-base lg:text-lg">Uploaded by {uploadedBy !== null ? uploadedBy : 'User'} on {returnCreationDate()}</p>
                        </div>
                    </div>
                </Link>
                {searchMatches.length !== 0 ? <div className="flex hover:bg-gray-200 w-1/12 self-stretch items-center justify-center" 
                    onClick={() => setIsActive(!isActive)}>
                        { 
                            isActive ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
                        }
                </div> : ''}
            </div>
            {isActive && <div className="p-4 flex flex-col items-start">{returnSearchMatches}</div>}
        </div>
    );
}

export default VideoSearchResultCard;
