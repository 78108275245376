/**
 * Signs out from application
 */

import { useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

export const SignOut = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            sessionStorage.clear()
            localStorage.clear();
        }
    }

    handleLogout("redirect")
    return ( <Router forceRefresh={true}><Redirect to="/" /></Router> )
}