import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';

const ProjectCard = ({id, name, userName, thumbnailVideoId, thumbnailId, lastModified}) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        let mounted = true;
            const getThumbnail = async () => {
                if(mounted) {
                    const {data}= await api.get(`/thumbnail?videoid=${thumbnailVideoId}&thumbnailid=${thumbnailId}`, {
                        responseType: 'blob',
                        timeout: 30000
                    });
                    setImageUrl(URL.createObjectURL(data));
                }    
            }
            getThumbnail();
            return () => mounted = false;
    }, [thumbnailVideoId, thumbnailId]);

    const returnLastUpdatedDate = (lastUpdated) => {
        let date = new Date(lastUpdated);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    return(
        <Link to={`/project/${id}`} className="stretched-link text-decoration-none flex overflow-hidden border border-b col-start-1 col-span-4">
            <div className="hover:bg-gray-200 items-center col-span-4 grid grid-cols-4 w-full">
                <div className="min-w-28"><img className="max-h-16 p-1" src={imageUrl}  alt={`Thumbnail of ${name}`} /></div>
                <span className="text-sm sm:text-base md:text-lg lg:text-xl col-start-2">{name}</span>
                <span className="hidden xs:block text-sm md:text-base lg:text-lg col-start-3">{userName !== null ? userName : 'User'}</span> 
                <span className="hidden xs:block text-sm md:text-base lg:text-lg col-start-4">{returnLastUpdatedDate(lastModified)}</span>
            </div>
        </Link>
    );
}

export default ProjectCard;