import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { initUser, initEdit, deleteUser, setUserList } from '../../actions/users';
import { Loading, Alert } from './utils';
import Pagination from './pagination';
import Table from './table';
import './styles/index.css';

const ListUsers = ({
  users,
  setUserList,
  history,
  initUser,
  initEdit,
  deleteUser,
  isLoading,
  error,
  deleteError
}) => {
  useEffect(() => {
    initUser();
    initEdit();
    setUserList(setUserAccess);
  }, [initUser, initEdit, setUserList]); //initUser, initEdit, setUserList

  const maxRowsPerPage = 10; // set max rows per page

  // state part ******
  const [query, setQuery] = useState(''); // search input
  //const [goToPage, setGoToPage] = useState(''); // goto input

  const [actAttr, setActAttr] = useState(''); // sort by which attribute
  const [sortType, setSortType] = useState(0); // default/asc/desc
  const [queryCur, setQueryCur] = useState(''); // store the query for search

  const [activePage, setActivePage] = useState(1); // current page

  const [userAccess, setUserAccess] = useState(false)

  // sortType: 1 for ascend, 2 for descend, 0 for default
  // if need remember page back, try redux

  // function define part *****

  // handle functions, process logic and actions
  const handleChange = e => {
    if (e.target.id === 'search') {
      setQuery(e.target.value);
    //} else if (e.target.id === 'goto') {
    //  setGoToPage(e.target.value);
    }
  };

  const handleCreate = e => {
    history.push('/createuser');
  };

  const handleSearch = e => {
    e.preventDefault();
    // protect out of page
    setActivePage(1);
    setQueryCur(query);
  };

  const handleEdit = id => {
    history.push(`/edituser/${id}`);
  };

  const handleDelete = id => {
    deleteUser(id);
    // setDeleteId(id);
  };

  /*
  const handlePageGoTo = e => {
    // console.log(e.target.tagName); // FORM
    e.preventDefault();
    if (
      !isNaN(goToPage) &&
      goToPage >= 1 &&
      goToPage <=
        parseInt(
          (activeUser(queryCur, sortType, users, actAttr).length - 1) /
            maxRowsPerPage
        ) +
          1
    )
      setActivePage(parseInt(goToPage)); // prevent invalid input
    // in case decimal: 1.2, 2.6, ...
  };

  */

  const handleSort = e => {
    if (e.target.id === actAttr) {
      setSortType((sortType + 1) % 3);
    } else {
      setSortType(1);
    }
    // Clear the sort type from another attribute
    setActAttr(e.target.id);
  };

  // -------------------------------------------------------
  // child function for selectUser, sort logic
  const sortUserByAttr = (users, attribute) => {
    // Don't change the USERS array!
    // ignore Upper and Lower differences, sex can be F/M or whole word
    switch (attribute) {
      case 'fullname':
        return [...users].sort((a, b) =>
          a.fullname.toLowerCase().split(" ").pop() > b.fullname.toLowerCase().split(" ").pop()
            ? 1
            : a.fullname.toLowerCase().split(" ").pop() === b.fullname.toLowerCase().split(" ").pop()
              ? a.fullname.toLowerCase().totalWords.replace(/ .*/, '') > b.fullname.toLowerCase().totalWords.replace(/ .*/, '')
                ? 1
                : a.fullname.toLowerCase().totalWords.replace(/ .*/, '') === b.fullname.toLowerCase().totalWords.replace(/ .*/, '')
                  ? a.email.toLowerCase() > b.email.toLowerCase()
                    ? 1
                    : a.email.toLowerCase() === b.email.toLowerCase()
                      ? a.roles.toLowerCase() > b.roles.toLowerCase()
                        ? 1
                        : a.roles.toLowerCase() === b.roles.toLowerCase()
                          ? a.partitions.toString().toLowerCase() > b.partitions.toString().toLowerCase()
                            ? 1
                            : a.partitions.toString().toLowerCase() === b.partitions.toString().toLowerCase()
                              ? -1
                              : -1
                          : -1
                      : -1
                  : -1
              : -1
        );

      case 'email':
        return [...users].sort((a, b) =>
          a.email.toLowerCase() > b.email.toLowerCase()
            ? 1
            : a.email.toLowerCase() === b.email.toLowerCase()
              ? a.fullname.toLowerCase().split(" ").pop() > b.fullname.toLowerCase().split(" ").pop()
                ? 1
                : a.fullname.toLowerCase().split(" ").pop() === b.fullname.toLowerCase().split(" ").pop()
                  ? a.fullname.toLowerCase().totalWords.replace(/ .*/, '') > b.fullname.toLowerCase().totalWords.replace(/ .*/, '')
                    ? 1
                    : a.fullname.toLowerCase().totalWords.replace(/ .*/, '') === b.fullname.toLowerCase().totalWords.replace(/ .*/, '')

                      ? a.roles.toLowerCase() > b.roles.toLowerCase()
                        ? 1
                        : a.roles.toLowerCase() === b.roles.toLowerCase()
                          ? a.partitions.toString().toLowerCase() > b.partitions.toString().toLowerCase()
                            ? 1
                            : a.partitions.toString().toLowerCase() === b.partitions.toString().toLowerCase()
                              ? -1
                              : -1
                          : -1
                      : -1
                  : -1
              : -1
        );

      case 'sex':
        return [...users].sort((a, b) =>
          a.sex.toLowerCase().slice(0, 1) > b.sex.toLowerCase().slice(0, 1)
            ? 1
            : a.sex.toLowerCase().slice(0, 1) === b.sex.toLowerCase().slice(0, 1)
            ? a.firstname.toLowerCase() > b.firstname.toLowerCase()
              ? 1
              : a.firstname.toLowerCase() === b.firstname.toLowerCase()
              ? a.lastname.toLowerCase() > b.lastname.toLowerCase()
                ? 1
                : a.lastname.toLowerCase() === b.lastname.toLowerCase()
                ? a.age > b.age
                  ? 1
                  : -1
                : -1
              : -1
            : -1
        );

      case 'age':
        // console.log([...users][0].age);
        return [...users].sort((a, b) =>
          a.age > b.age
            ? 1
            : a.age === b.age
            ? a.firstname.toLowerCase() > b.firstname.toLowerCase()
              ? 1
              : a.firstname.toLowerCase() === b.firstname.toLowerCase()
              ? a.lastname.toLowerCase() > b.lastname.toLowerCase()
                ? 1
                : a.lastname.toLowerCase() === b.lastname.toLowerCase()
                ? a.sex.toLowerCase().slice(0, 1) >
                  b.sex.toLowerCase().slice(0, 1)
                  ? 1
                  : -1
                : -1
              : -1
            : -1
        );

      default:
        return [...users];
    }
  };

  // @ searchUser, selectUser are child functions for setActiveUser
  const searchUser = (users, queryCur) => {
    // this func filter users based on search query
    return users.filter(
      user =>
        user.fullname
          .toLowerCase()
          .indexOf(queryCur.toString().toLowerCase()) !== -1 ||
        user.email
          .toLowerCase()
          .indexOf(queryCur.toString().toLowerCase()) !== -1 
          ||
        user.partitions
          .toString()
          .toLowerCase()
          .indexOf(queryCur.toString().toLowerCase()) !== -1   
    );
  };

  const selectSort = (sortType, users, actAttr) => {
    // this func sort users based on sort type
    switch (sortType) {
      case 1:
        return sortUserByAttr(users, actAttr);
      case 2:
        return [...sortUserByAttr(users, actAttr)].reverse();
      default:
        return users;
    }
  };

  // IMPORTANT function to calculate active users
  const activeUser = (queryCur, sortType, users, actAttr) => {
    // this func return results after search and then sorting
    if (queryCur) {
      const searchedUsers = searchUser(users, queryCur);
      return selectSort(sortType, searchedUsers, actAttr);
    } else {
      return selectSort(sortType, users, actAttr);
    }
  };
  // old expression:
  // (queryCur
  //   ? (sortType === 1
  //       ? sortUserByAttr(users, actAttr)
  //       : sortType === 2
  //       ? [...sortUserByAttr(users, actAttr).reverse()]
  //       : users
  //     ).filter(user => searchUser(user, queryCur))
  //   : sortType === 1
  //   ? sortUserByAttr(users, actAttr)
  //   : sortType === 2
  //   ? [...sortUserByAttr(users, actAttr).reverse()]
  //   : users
  // )
  // ------------------------------------------------------------

  //render part ********
  if (
    activeUser(queryCur, sortType, users, actAttr).length > 0 &&
    activeUser(queryCur, sortType, users, actAttr).length ===
      (activePage - 1) * maxRowsPerPage
  ) {
    // if it is this page's last user, after delete, back to prev page
    // empty list stay in 1st page
    setActivePage(activePage - 1);
  }


  if (!userAccess) {
    return 'Not Authorized'
  } else {

  return (
    <>
    <div className="relative flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100 bg-no-repeat bg-cover">
      <div className="absolute bg-white opacity-60 inset-0 z-0"></div>
      <div className=" w-full p-10 bg-white rounded-xl z-10">
        <div className="text-center">
            <h2 className="mt-5 text-3xl font-bold text-gray-900">
              User list
            </h2>
            { /*<p className="mt-2 text-sm text-gray-400">Lorem ipsum is placeholder text.</p> */}
        </div>

        <div className="mt-8 flex w-full p-2 space-x-3">     
          <div className="inline-block w-1/2">
            <form className="float-left w-full flex" onSubmit={e => handleSearch(e)}>
              <div className="w-3/5 inline-block text-left border p-2 mr-1 px-12"> 
                  <input 
                      placeholder='Enter text to search'
                      aria-label='Search'
                      id='search'
                      className="h-auto py-2 px-12"
                      value={query}
                      onChange={e => handleChange(e)}
                    />
              </div>  
              <div className="w-1/5 float-right inline-block p-2"> 
                <input className="ml-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 roundedbtn btn-outline-success " type="submit" value="Search" />
              </div>
            </form>
          </div>

            {/* <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
            <div className="inline-block w-1/2 py-2"> 
              <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 roundedbtn btn-outline-success float-right'
                onClick={e => handleCreate()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
</svg> Create
              </button>
            </div>

        </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <Table
              queryCur={queryCur}
              sortType={sortType}
              users={users}
              actAttr={actAttr}
              maxRowsPerPage={maxRowsPerPage}
              activeUser={activeUser}
              activePage={activePage}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              handleSort={handleSort}
            />

            {/* Pagination Bar */}
            <div className="items-center">
            <Pagination
              queryCur={queryCur}
              sortType={sortType}
              users={users}
              actAttr={actAttr}
              maxRowsPerPage={maxRowsPerPage}
              activeUser={activeUser}
              activePage={activePage}
              setActivePage={setActivePage}
            />
            {error && <Alert waring='server' item='get' />}
            {deleteError && <Alert waring='server' item='delete' />}
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
    
    </>
  );}
};

const mapStateToProps = state => {
  return {
    users: state.users.users,
    isLoading: state.users.isLoading,
    error: state.users.error,
    deleteError: state.users.deleteError
  };
};

const mapStateToDispatch = dispatch => {
  return {
    setUserList: (setUserAccess) => dispatch(setUserList(setUserAccess)),
    initUser: () => dispatch(initUser()),
    initEdit: () => dispatch(initEdit()),
    deleteUser: id => dispatch(deleteUser(id))
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ListUsers);
