import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../assets/css/SidePanel.css";

/**
 * HomeLink, LibraryLink, UploadLink
 * @returns Styled links to application sub-components
 */
const HomeLink = () => {
    return(
        <>
            <Link to="/" className="block py-3 px-3 sm:px-0 align-middle text-gray-600 no-underline hover:text-light-blue-400">
                <div className="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span className="hidden sm:block w-full inline-block pb-1 md:pb-0 text-sm">Home</span>
                </div>
            </Link> 
        </>
    );
}

const LibraryLink = () => {
    const location = useLocation();
    return(
        <>
            <Link to="/library" onClick={location.pathname === '/library' ? () => window.location.reload() : null} 
                className="block py-3 px-3 sm:px-0 align-middle text-gray-600 no-underline hover:text-light-blue-400" replace>
                <div className="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                    </svg>
                    <span className="hidden sm:block w-full inline-block pb-1 md:pb-0 text-sm">Video Library</span>
                </div>
            </Link>
        </>
    );
}

const UploadLink = () => {
    return(
        <>
            <Link to="/upload" className="block py-3 px-3 sm:px-0 align-middle text-gray-600 no-underline hover:text-light-blue-400">
                <div className="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span className="hidden sm:block w-full inline-block pb-1 md:pb-0 text-sm">Upload Video</span>
                </div>
            </Link>
        </>
    );
}

const UserManagementLink = () => {
    return(
        <>
            <Link to="/listusers" className="block py-3 px-3 sm:px-0 align-middle text-gray-600 no-underline hover:text-light-blue-400">
                <div className="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span className="hidden sm:block w-full inline-block pb-1 md:pb-0 text-sm">User Management</span>
                </div>
            </Link>
        </>
    );
}



export {HomeLink, LibraryLink, UploadLink, UserManagementLink};
