import { combineReducers } from 'redux';
import users from './users';
import createUser from './createuser';
import editUser from './edituser';
// import deleteUser from './deleteuser';
import alert from './alert';
import getUser from './getuser';
import auth from "./auth";
import message from "./message";

const reducer = combineReducers({
  users,
  createUser,
  editUser,
  // deleteUser,
  getUser,
  alert,
  message,
  auth
});

export default reducer;
