

export const ProgressBar = (props) => {
//console.log(props)
const progressPercentage = Math.round(props.pct)
    return (
        <div>
        <div role="alert">
            
            <p>Uploading {`${progressPercentage}%`}</p>
        </div>
        <div className='h-1 w-full bg-gray-300'>

            <div
                style={{ width: `${progressPercentage}%`}}
                className={`h-full ${
                    progressPercentage < 70 ? 'bg-red-600' : 'bg-green-600'}`}>
            </div>
        </div>
        </div>
    );
};